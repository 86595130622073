import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/Header'

function BuySellSignalPage() {
  const [activeTab, setActiveTab] = useState('img-corn')

  const signalItems = [
    {
      id: 'img-corn',
      src: 'https://micms.stonex.com/sites/default/files/screenshots/99953bbb-7d73-4e95-baa0-c8790506a6a1.png',
      title: 'Corn/Milo'
    },
    {
      id: 'img-soyabean',
      src: 'https://micms.stonex.com/sites/default/files/screenshots/7c00bfc7-d531-4cbe-9f92-77a80abfcb39.png',
      title: 'Soybeans'
    },
    {
      id: 'img-wheat',
      src: 'https://micms.stonex.com/sites/default/files/screenshots/a5cb5c8d-4d1c-4fd7-be8c-3c003e11e0fb.png',
      title: 'KC Wheat'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Buy/Sell Signal</title>
      </Helmet>
      <Header>Buy/Sell Signal</Header>
      <h1 className="text-xl font-semibold text-gray-900">Buy/Sell Signal</h1>

      <div className="flex w-full flex-col items-center justify-center py-6">
        <div className="mb-4 flex w-full justify-center space-x-4">
          {signalItems.map(({ id, title }) => (
            <button
              key={id}
              type="button"
              className={`rounded-lg px-4 py-2 font-semibold transition-colors ${
                activeTab === id ? 'bg-green_medium text-white' : 'bg-gray-200 text-gray-700'
              }`}
              onClick={() => setActiveTab(id)}
            >
              {title}
            </button>
          ))}
        </div>

        {signalItems.map(
          ({ id, src, title }) =>
            activeTab === id && (
              <section key={id} className="w-full text-center">
                <h2 className="mb-2 text-xl font-bold">{title}</h2>
                <img
                  src={src}
                  alt={title}
                  className="mx-auto h-auto w-[75%] max-w-4xl rounded-lg object-contain"
                />
              </section>
            )
        )}
      </div>
    </>
  )
}

export default BuySellSignalPage
