/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import { Helmet } from 'react-helmet'

import CashSalesPage from './clientDetails/CashSalesPage'
import ClientGeneratedReportsPage from './clientDetails/ClientGeneratedReportsPage'
import ClientGeneratedMonthlyReportsPage from './clientDetails/ClientGeneratedMonthlyReportsPage'
import InsurancePage from './clientDetails/InsurancePage'
import LayoutWrapper from '../components/layouts/LayoutWrapper'
import NavTabs from '../components/NavTabs'
import PlanDetailsPage from './clientDetails/plan/PlanDetailsPage'
import ProfilesPage from './clientDetails/ProfilesPage'
import ReportsPage from './ReportsPage'
import SummaryPage from './clientDetails/SummaryPage'
import { CLIENT_ROUTES, PLAN_ROUTES } from '../constants/routes'
import { FilterContextProvider } from '../context/FilterContext'
import MarketCommentaryPage from './MarketCommentaryPage'
import BuySellSignalPage from './BuySellSignalPage'

const tabs = [
  {
    name: 'Overview',
    href: CLIENT_ROUTES.SUMMARY,
    value: 'SUMMARY'
  },
  {
    name: 'Commodities',
    href: CLIENT_ROUTES.PROFILE,
    value: 'PROFILE'
  },
  {
    name: 'Contracts',
    href: CLIENT_ROUTES.CASHSALES,
    value: 'CASHSALES'
  },
  {
    name: 'Expenses',
    href: PLAN_ROUTES.PLAN,
    value: 'PLAN'
  },
  {
    name: 'Insurance',
    href: CLIENT_ROUTES.INSURANCE,
    value: 'INSURANCE'
  },
  {
    name: 'Reports',
    href: CLIENT_ROUTES.REPORTS,
    value: 'REPORT'
  }
]

function Tab({ tabID }) {
  let tabElement
  if (tabID === CLIENT_ROUTES.PROFILE) {
    tabElement = <ProfilesPage />
  } else if (tabID === CLIENT_ROUTES.CASHSALES) {
    tabElement = <CashSalesPage />
  } else if (tabID === CLIENT_ROUTES.SUMMARY) {
    tabElement = <SummaryPage />
  } else if (tabID === CLIENT_ROUTES.PLAN) {
    tabElement = <PlanDetailsPage />
  } else if (tabID === CLIENT_ROUTES.REPORTS) {
    tabElement = <ReportsPage />
  } else if (tabID === CLIENT_ROUTES.MARKET_COMMENTARY) {
    tabElement = <MarketCommentaryPage />
  } else if (tabID === CLIENT_ROUTES.BUY_SELL_SIGNAL) {
    tabElement = <BuySellSignalPage />
  } else if (tabID === CLIENT_ROUTES.INSURANCE) {
    tabElement = <InsurancePage />
  } else if (tabID === CLIENT_ROUTES.GENERATED_REPORTS) {
    tabElement = <ClientGeneratedReportsPage />
  } else if (tabID === CLIENT_ROUTES.GENERATED_MONTHLY_REPORTS) {
    tabElement = <ClientGeneratedMonthlyReportsPage />
  }

  return <NavTabs tabs={tabs} tabElement={tabElement} />
}

export default function ClientsDetailsPage({ tab }) {
  return (
    <>
      <Helmet>
        <title>Customers Details</title>
      </Helmet>

      <FilterContextProvider>
        <LayoutWrapper>
          <Tab tabID={tab} />
        </LayoutWrapper>
      </FilterContextProvider>
    </>
  )
}
