export const VISITOR_ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  UNAUTHORIZED: '/unauthorized',
  RESET_PASSWORD: '/reset-password',
  REPORT: '/reports/:clientId'
}

export const USER_ROUTES = {
  HOME: '/',
  MANAGERS: '/managers',
  ADMIN: '/admin',
  IMPORTS: '/:id/:productionYearId/imports',
  REPORTS: 'reports?*',
  PRICES: '/prices',
  SET_PASSWORD_ADMIN: '/set-password',
  CLIENTS_PRODUCTION_YEARS: '/clients/:id',
  CLIENTS_DETAILS: '/clients/:id/:productionYearId',
  CHANGE_EMAIL_ADDRESS: '/account/change-email-address',
  CONFIRM_NEW_EMAIL_ADDRESS: '/account/confirm-new-email-address/:accessToken',
  CHANGE_PASSWORD: '/account/change-password',
  PROFILE: '/account/profile',
  LOGOUT: '/account/logout',
  SET_PASSWORD: '/account/set-password',
  ACCEPT_INVITE: '/account/accept-invite'
}

export const CLIENT_ROUTES = {
  SUMMARY: 'summary',
  PROFILE: 'profiles',
  CASHSALES: 'cash-sales',
  INSURANCE: 'insurance',
  PLAN: 'plan/*',
  REPORTS: 'reports',
  MARKET_COMMENTARY: 'market-commentary',
  BUY_SELL_SIGNAL: 'buy-sell-signal',
  GENERATED_REPORTS: 'reports/generated',
  GENERATED_MONTHLY_REPORTS: 'reports/monthly/generated'
}

export const PLAN_ROUTES = {
  PLAN: 'plan',
  PLAN_TARGETS: 'plan-targets',
  EXPENSES_PER_ACRE: 'expenses-per-acre',
  EXPENSES_PER_HEAD: 'expenses-per-head',
  EXPENSES_GROSS: 'expenses-gross',
  ADHOC_INCOME: 'adhoc-income',
  PROFIT_GOALS: 'profit-goals',
  MANAGE_CUSTOM_EXPENSES: 'manage-custom-expenses'
}

export const ADMIN_ROUTES = {
  IMPORTS: '/admin/imports/:id/:productionYearId',
  REPORTS: '/admin/reports',
  PRICES: '/admin/prices',
  FUTURES: '/admin/futures',
  DEFAULT_BASIS: '/admin/default-basis',
  SET_PASSWORD: '/admin/set-password'
}
