import React, { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom'

import {
  CashIcon,
  ChartSquareBarIcon,
  ClipboardListIcon,
  CreditCardIcon,
  DocumentTextIcon,
  OfficeBuildingIcon,
  ServerIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  UserGroupIcon,
  XIcon,
  DownloadIcon,
  CurrencyDollarIcon,
  SpeakerphoneIcon
} from '@heroicons/react/outline'
import { PencilAltIcon } from '@heroicons/react/solid'

import ChangeProductionYearModal from './modals/ChangeProductionYearModal'
import logo from '../assets/logo-color.png'
import roles from '../constants/roles'
import UserAvatar from './avatars/UserAvatar'
import { CLIENT_ROUTES, PLAN_ROUTES, USER_ROUTES } from '../constants/routes'
import { useAuth } from '../hooks/useAuth'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useToggle } from '../hooks/useToggle'
import { useGetUser } from '../services/queries/useGetUser'
import Loader from './Loader'
import { getName } from '../utils/helper'

const userNavigation = [
  { name: 'Profile', href: USER_ROUTES.PROFILE },
  { name: 'Change Email Address', href: USER_ROUTES.CHANGE_EMAIL_ADDRESS },
  { name: 'Change Password', href: USER_ROUTES.CHANGE_PASSWORD },
  { name: 'Log out', href: USER_ROUTES.LOGOUT }
]

const allNavItems = [
  {
    name: 'Customers',
    href: USER_ROUTES.HOME,
    value: 'CLIENTS',
    icon: UserGroupIcon,
    roles: [roles.MANAGER, roles.SUPER_MANAGER]
  },
  {
    name: 'Managers',
    href: USER_ROUTES.MANAGERS,
    value: 'MANAGERS',
    icon: ServerIcon,
    roles: [roles.SUPER_MANAGER]
  },
  {
    name: 'Admin',
    href: USER_ROUTES.ADMIN,
    value: 'ADMIN',
    icon: OfficeBuildingIcon,
    roles: [roles.MANAGER, roles.SUPER_MANAGER]
  }
]

export default function Sidebar() {
  const url = useParams()
  const [isOpen, toggleIsOpen] = useToggle()
  const [searchParams] = useSearchParams()
  const adminReportsQueryParams = Object?.fromEntries([...searchParams])
  const adminReportsClients = adminReportsQueryParams?.clients
  const adminReportsProductionYears = adminReportsQueryParams?.productionYears

  const importClientId = adminReportsClients?.split(',')[0]
  const { user } = useAuth() || {}

  const loggedInUser = user

  const userRole = loggedInUser?.kind

  const location = useLocation()

  const userNavItems = allNavItems.filter((navItem) => navItem.roles.includes(userRole))

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [subMenu, setSubMenu] = useLocalStorage('SUB_MENU', false)
  const [nestedNavState, setNestedNavState] = useState(undefined)

  const isLoggedInUserClient = loggedInUser?.kind === roles.CLIENT
  const clientId = url?.id || importClientId

  const { data, loading } = useGetUser(clientId, {
    skip: !clientId || isLoggedInUserClient
  })

  const client = (isLoggedInUserClient && loggedInUser) || data?.getUser

  const userName = getName(client)

  const isAdminRoute = location?.pathname?.includes('/admin')
  let sideHeader
  if (url?.id && url?.productionYearId && !isAdminRoute) {
    sideHeader = (
      <div className="flex w-full justify-between">
        <div className="w-full flex-col items-start">
          <div className="text-lg">{userName}</div>
          <div className="mt-2 flex items-end text-base font-medium">
            <div className="leading-4">{url?.productionYearId}&nbsp;</div>
            <div
              className="mr-4 h-4 w-4 flex-shrink-0 cursor-pointer text-emerald-300"
              aria-hidden="true"
              onClick={toggleIsOpen}
            >
              <PencilAltIcon />
            </div>
          </div>
        </div>
      </div>
    )
  } else if (url?.id && !isAdminRoute) {
    sideHeader = (
      <div className="flex w-full justify-between">
        <div className="w-full flex-col items-start">
          <div className="text-lg">{userName}</div>
          <div className="mt-2 flex justify-between text-base font-medium leading-4">
            Select a production year
          </div>
        </div>
      </div>
    )
  }

  const secondaryNavigation = [
    {
      name: 'Commodities',
      href: CLIENT_ROUTES.PROFILE,
      value: 'COMMODITIES',
      icon: UserCircleIcon
    },
    {
      name: 'Contracts',
      href: CLIENT_ROUTES.CASHSALES,
      value: 'CONTRACTS',
      icon: ClipboardListIcon
    },
    {
      name: 'Expenses',
      href: CLIENT_ROUTES.PLAN,
      value: 'EXPENSES',
      icon: CashIcon,
      children: [
        {
          name: 'Summary',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.PLAN_TARGETS}`,
          value: 'SUMMARY'
        },
        {
          name: 'Crop Expenses',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_PER_ACRE}`,
          value: 'CROP_EXPENSES'
        },
        {
          name: 'Livestock Expenses',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_PER_HEAD}`,
          value: 'LIVESTOCK_EXPENSES'
        },
        {
          name: 'Gross Expenses',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_GROSS}`,
          value: 'GROSS_EXPENSES'
        },
        {
          name: 'Ad-hoc Income',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.ADHOC_INCOME}`,
          value: 'AD_HOC_INCOME'
        },
        {
          name: 'Goals',
          href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.PROFIT_GOALS}`,
          value: 'GOALS'
        }
      ]
    },
    { name: 'Insurance', href: CLIENT_ROUTES.INSURANCE, value: 'INSURANCE', icon: ShieldCheckIcon },
    { name: 'Overview', href: CLIENT_ROUTES.SUMMARY, value: 'OVERVIEW', icon: ChartSquareBarIcon },
    { name: 'Reports', href: CLIENT_ROUTES.REPORTS, value: 'REPORTS', icon: DocumentTextIcon },
    {
      name: 'Market Commentary',
      href: CLIENT_ROUTES.MARKET_COMMENTARY,
      value: 'MARKET_COMMENTARY',
      icon: SpeakerphoneIcon
    },
    {
      name: (
        <span className="flex items-center gap-1">
          Buy/Sell Signal{' '}
          <span className="rounded bg-red-500 py-0.5 px-1 text-xs text-white">NEW</span>
        </span>
      ),
      href: CLIENT_ROUTES.BUY_SELL_SIGNAL,
      value: 'BUY_SELL_SIGNAL',
      icon: CreditCardIcon
    }
  ]

  const importsHref = `${url?.id || importClientId}/${
    url?.productionYearId || adminReportsProductionYears?.substring(0, 4)
  }`
  const reportsHref =
    location?.search || `clients=${url.id}&productionYears=${url.productionYearId}`
  const adminSecondaryNavigation = [
    { name: 'Imports', href: `imports/${importsHref}`, value: 'IMPORTS', icon: DownloadIcon },
    { name: 'Reports', href: `reports?${reportsHref}`, value: 'REPORTS', icon: DocumentTextIcon },
    { name: 'Prices', href: `prices`, value: 'PRICES', icon: CurrencyDollarIcon },
    { name: 'Futures Prices', href: `futures`, value: 'FUTURES PRICES', icon: CurrencyDollarIcon },
    {
      name: 'Default Basis',
      href: `default-basis`,
      value: 'DEFAULT BASIS',
      icon: CurrencyDollarIcon
    }
  ]

  return (
    <div>
      {isOpen && <ChangeProductionYearModal isOpen={isOpen} closeModal={toggleIsOpen} />}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-green_dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center justify-center bg-white p-4">
                    <img className="h-12 w-auto" src={logo} alt="Workflow" />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {userNavItems.map((navItem) =>
                      !navItem.children ? (
                        <NavLink
                          end
                          key={navItem.name}
                          to={navItem.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'bg-green_clicked text-white'
                                : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                            )
                          }
                        >
                          <navItem.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                            aria-hidden="true"
                          />
                          {navItem.name}
                        </NavLink>
                      ) : (
                        <Disclosure as="div" key={navItem.name} className="space-y-1">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? 'bg-green_clicked text-white'
                                      : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                    'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-base font-medium focus:outline-none focus:ring-2 focus:ring-emerald-500'
                                  )
                                }
                              >
                                <navItem.icon
                                  className="mr-3 h-6 w-6 flex-shrink-0 text-emerald-300 group-hover:bg-emerald-600"
                                  aria-hidden="true"
                                />
                                <span className="ml-1 flex-1">{navItem.name}</span>
                                <svg
                                  className={classNames(
                                    open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                                    'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                  )}
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                >
                                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-1">
                                {navItem.children.map((subItem) => (
                                  <NavLink
                                    end
                                    key={subItem.name}
                                    to={subItem.href}
                                    className="group ml-1 flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-slate-300  hover:bg-emerald-600"
                                  >
                                    {subItem.name}
                                  </NavLink>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    )}
                  </nav>
                  <div className="mt-2 border-t border-emerald-800">
                    {!isAdminRoute && (
                      <div className="py-4 md:w-64">
                        <div className="mx-auto max-w-7xl px-4 sm:pl-4 lg:pl-4">
                          <div className="mb-4 ml-1 h-9 min-h-full text-xl font-bold text-white">
                            {!loading && sideHeader}
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && <Loader />}
                    {url?.productionYearId && !isAdminRoute ? (
                      <div className="ml-1 mt-8 space-y-1" role="group">
                        {secondaryNavigation.map((item) =>
                          !item.children ? (
                            <NavLink
                              key={item.name}
                              to={`clients/${url.id}/${url.productionYearId}/${item.href}`}
                              onClick={() => setNestedNavState(item.value)}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? 'bg-green_clicked text-white'
                                    : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                  'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                                )
                              }
                            >
                              <item.icon
                                className="mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                                aria-hidden="true"
                              />
                              <span className="truncate">{item.name}</span>
                            </NavLink>
                          ) : (
                            <Disclosure
                              as="div"
                              key={item.name}
                              {...(subMenu && { defaultOpen: true })}
                              className="space-y-1 px-1"
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={() =>
                                      classNames(
                                        item.value === nestedNavState
                                          ? 'bg-green_clicked text-white'
                                          : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                        'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                                      )
                                    }
                                    onClick={() => setSubMenu(true)}
                                  >
                                    <item.icon
                                      className="mr-3 h-6 w-6 flex-shrink-0 text-emerald-300 group-hover:bg-emerald-600"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-1 flex-1">{item.name}</span>
                                    <svg
                                      className={classNames(
                                        open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                                        'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                      )}
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                    >
                                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                    </svg>
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="space-y-1">
                                    {item.children.map((subItem) => (
                                      <NavLink
                                        key={subItem.name}
                                        to={`clients/${url.id}/${url.productionYearId}/${subItem.href}`}
                                        className="group ml-1 flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-slate-300 hover:bg-gray-50 hover:bg-emerald-600"
                                      >
                                        {subItem.name}
                                      </NavLink>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )
                        )}
                      </div>
                    ) : (
                      (url?.productionYearId && isAdminRoute) ||
                      (isAdminRoute && !loading && (
                        <div className="ml-1 space-y-1" role="group">
                          {adminSecondaryNavigation.map((item) => (
                            <NavLink
                              key={item.name}
                              to={`/admin/${item.href}`}
                              onClick={() => setNestedNavState(item.value)}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? 'bg-green_clicked text-white'
                                    : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                  'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                                )
                              }
                              state={{
                                name: userName
                              }}
                            >
                              <item.icon
                                className="mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                                aria-hidden="true"
                              />
                              <span className="truncate">{item.name}</span>
                            </NavLink>
                          ))}
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div className="flex flex-shrink-0 border-t border-emerald-800 p-4">
                  <Menu as="div" className="relative ml-3 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full p-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-600">
                        <div className="flex items-center">
                          <span className="sr-only">Open user menu</span>
                          <UserAvatar user={loggedInUser} />
                          <div className="ml-3">
                            <p className="text-sm font-medium text-white">
                              {getName(loggedInUser)}
                            </p>
                            <p className="text-xs font-medium text-emerald-200 group-hover:text-white">
                              View profile
                            </p>
                          </div>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true" />
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex min-h-0 flex-1 flex-col bg-green_dark">
          <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center justify-center bg-white p-4">
              <img className="h-12 w-auto" src={logo} alt="Workflow" />
            </div>
            <nav className="mt-5 px-2">
              {userNavItems.map((navItem) =>
                !navItem.children ? (
                  <NavLink
                    end
                    key={navItem.name}
                    to={navItem.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-green_clicked text-white'
                          : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                        'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                      )
                    }
                  >
                    <navItem.icon
                      className="mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                      aria-hidden="true"
                    />
                    {navItem.name}
                  </NavLink>
                ) : (
                  <Disclosure as="div" key={navItem.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'bg-green_clicked text-white'
                                : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                              'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-base font-medium focus:outline-none focus:ring-2 focus:ring-emerald-500'
                            )
                          }
                        >
                          <navItem.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-emerald-300"
                            aria-hidden="true"
                          />
                          <span className="ml-1 flex-1">{navItem.name}</span>
                          <svg
                            className={classNames(
                              open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                            )}
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {navItem.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              href={subItem.href}
                              className="group ml-1 flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-slate-300 hover:bg-gray-50 hover:bg-emerald-600"
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
            <div className="mt-2 border-t border-emerald-800">
              <div className="py-4 md:w-64">
                <div className="mx-auto max-w-7xl px-4 sm:pl-4 lg:pl-4">
                  {!isAdminRoute && (
                    <div className="mb-4 ml-1 h-9 min-h-full text-xl font-bold text-white">
                      {!loading && sideHeader}
                    </div>
                  )}
                </div>
              </div>
              {loading && <Loader />}
              {url?.productionYearId && !isAdminRoute && !loading && (
                <div className="mt-8 flex-col space-y-1 px-2" role="group">
                  {secondaryNavigation.map((item) =>
                    !item.children ? (
                      <NavLink
                        key={item.name}
                        to={`clients/${url.id}/${url.productionYearId}/${item.href}`}
                        onClick={() => {
                          setSubMenu(false)
                          setNestedNavState(item.value)
                        }}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? 'bg-green_clicked text-white'
                              : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                            'group flex items-center rounded-md py-2 text-sm font-medium'
                          )
                        }
                      >
                        <item.icon
                          className="ml-2 mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </NavLink>
                    ) : (
                      <Disclosure
                        as="div"
                        key={item.name}
                        className="space-y-1"
                        {...(subMenu && { defaultOpen: true })}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? 'bg-green_clicked text-white'
                                    : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                  'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-emerald-500'
                                )
                              }
                              onClick={() => {
                                setSubMenu(true)
                              }}
                            >
                              <item.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-emerald-300 group-hover:bg-emerald-600"
                                aria-hidden="true"
                              />
                              <span className="ml-1 flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                                  'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={`clients/${url.id}/${url.productionYearId}/${subItem.href}`}
                                  className={({ isActive }) =>
                                    classNames(
                                      isActive
                                        ? 'bg-green_clicked text-white'
                                        : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                                      'group ml-1 flex w-full items-center rounded-md py-2 pl-11 pr-2 text-left text-sm font-medium text-slate-300 focus:outline-none focus:ring-2 focus:ring-emerald-500'
                                    )
                                  }
                                  onClick={() => {
                                    setNestedNavState('')
                                  }}
                                >
                                  {subItem.name}
                                </NavLink>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  )}
                </div>
              )}
              {((url?.productionYearId && isAdminRoute && !loading) ||
                (!loading && isAdminRoute) ||
                (location?.search && isAdminRoute)) && (
                <div className="ml-1 space-y-1" role="group">
                  {adminSecondaryNavigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={`admin/${item.href}`}
                      onClick={() => setNestedNavState(item.value)}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'bg-green_clicked text-white'
                            : 'text-white hover:bg-emerald-600 hover:bg-opacity-75',
                          'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                        )
                      }
                      state={{
                        name: userName
                      }}
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-emerald-300"
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="border-t border-emerald-800">
            <Menu as="div" className="relative ml-3 flex-shrink-0 py-4">
              <div>
                <Menu.Button className="flex h-full w-full text-sm text-white">
                  <div className="flex items-center">
                    <span className="sr-only">Open user menu</span>
                    <UserAvatar user={loggedInUser} />
                    <div className="ml-3">
                      <p className="text-sm font-medium text-white">{getName(loggedInUser)}</p>
                    </div>
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute -top-2 left-0 right-0 w-56 origin-top-right -translate-y-full transform divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block py-2 px-4 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-2">
        <div className="sticky top-0 z-10 bg-green_medium pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <div className="ml-4 flex flex-shrink-0 items-center px-4">
              <img className="ml-16 h-12 w-auto" src={logo} alt="Workflow" />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
